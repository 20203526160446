export default [
  {
    path: '/invoicing/tryout',
    name: 'invoicing-tryout',
    component: () => import('@/views/invoicing/Tryout.vue'),
    meta: {
      pageTitle: 'Invoicing Tryout',
      permissions: ['invoicing-tryout'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoicing/Index.vue'),
    meta: {
      pageTitle: 'Invoices',
      permissions: ['invoicing-tryout'],
    },
  },
]
