export default [
  {
    path: '/special-commodities',
    name: 'special-commodities',
    component: () => import('@/views/special-commodities/list/List.vue'),
    meta: {
      pageTitle: 'Commodities',
      permissions: ['view special-commodities'],
    },
  },
  {
    path: '/special-commodities/add',
    name: 'add-special-commodities',
    component: () => import('@/views/special-commodities/add/Add.vue'),
    meta: {
      pageTitle: 'Add commodity',
      breadcrumb: [
        {
          text: 'Commodities',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      permissions: ['create special-commodities'],
    },
  },
  {
    path: '/special-commodities/edit',
    name: 'edit-special-commodities',
    component: () => import('@/views/special-commodities/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit commodity',
      breadcrumb: [
        {
          text: 'Commodities',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      permissions: ['edit special-commodities'],
    },
  },
]
