export default [
  {
    path: '/parcels',
    name: 'parcels',
    component: () => import('@/views/parcels/Index.vue'),
    meta: {
      pageTitle: 'Parcels',
      permissions: ['view shipment'],
    },
  },
]
