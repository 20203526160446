import Vue from 'vue'
import axios from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'

export const login = (email, password, rememberMe) => useJwt.login({
  email,
  password,
  rememberMe,
})

export const setUserData = userData => {
  useJwt.setUserData(userData)
}

export const isAuthenticated = () => useJwt.getUserData() !== null && useJwt.getUserData() !== 'null'

export const logout = () => {
  // Remove userData
  Vue.prototype.$profile().data.user = null
  Vue.prototype.$activeCompany().data.company = null
  useJwt.removeToken()
  useJwt.setActiveCompany(null)
  useJwt.setUserData(null)

  axios.get('/v1/logout')
}
