export default {
  format(number, decimalPlaces) {
    if (!number) {
      return ''
    }

    if (!Number.isInteger(decimalPlaces)) {
      decimalPlaces = 0
    }

    const thousandsSeparator = ' '
    const decimalSeparator = '.'
    const str = this.roundToXDigits(number, decimalPlaces).toString()

    let int
    let decimals = ''
    if (str.indexOf('.') !== -1) {
      [int, decimals] = str.split('.')
    } else {
      int = str
    }

    let lastPlace = int.length
    const intArray = []
    for (let i = int.length - 3; i > 0; i -= 3) {
      intArray.push(int.substring(i, lastPlace))
      lastPlace = i
    }
    intArray.push(int.substring(0, lastPlace))

    int = intArray.reverse().join(thousandsSeparator)
    return decimals || decimalPlaces ? `${int}${decimalSeparator}${decimals.padEnd(decimalPlaces, '0')}` : int
  },
  roundToXDigits(value, digits) {
    if (!digits) {
      return value
    }
    return Math.round(value * (10 ** digits)) / (10 ** digits)
  },
}
