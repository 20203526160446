export default [
  {
    path: '/events-history',
    name: 'eventsHistory',
    component: () => import('@/views/shipments/cards/EventHistory.vue'),
    props: true,
    meta: {
      pageTitle: 'Shipment events history',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'Events history',
          active: true,
        },
      ],
    },
  },
]
