export default [
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import('@/views/shipments/Index.vue'),
    meta: {
      pageTitle: 'Shipments',
      permissions: ['view shipment'],
    },
  },
  {
    path: '/shipments/add',
    name: 'add-shipment',
    component: () => import('@/views/shipments/ShipmentForm.vue'),
    meta: {
      pageTitle: 'Add Shipment',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'Add Shipment',
          active: true,
        },
      ],
      permissions: ['create shipment', 'add manifest'],
    },
  },
  {
    path: '/shipments/:shipmentId/edit',
    name: 'edit-shipment',
    component: () => import('@/views/shipments/ShipmentForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Shipment Edit',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      permissions: ['edit shipment', 'add manifest'],
    },
  },
  {
    path: '/shipments/:shipmentId',
    name: 'shipment',
    component: () => import('@/views/shipments/View.vue'),
    props: true,
    meta: {
      data: {},
      pageTitle: 'Shipment view',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      permissions: ['view shipment'],
    },
  },
  {
    path: '/shipments/:shipmentId/missing-hs-codes',
    name: 'shipment',
    component: () => import('@/views/shipments/HsCodesMissing.vue'),
    props: true,
    meta: {
      data: {
        declaration_type: 'H7',
      },
      pageTitle: 'Commodities with no HS codes',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      permissions: ['view shipment'],
    },
  },
  {
    path: '/shipments/:shipmentId/missing-hs-codes-h1',
    name: 'shipment',
    component: () => import('@/views/shipments/HsCodesMissing.vue'),
    props: true,
    meta: {
      data: {
        declaration_type: 'H1',
      },
      pageTitle: 'Commodities with no HS codes',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      permissions: ['view shipment'],
    },
  },
]
