import Vue from 'vue'

export default {
  name: 'text',
  install() {
    Vue.prototype.$truncate = (content, length) => {
      const clamp = '...'
      return content.length > length ? content.slice(0, length) + clamp : content
    }
  },
}
