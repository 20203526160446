import Vue from 'vue'
import axios from '@/libs/axios'

export default {
  name: 'PermissionsComponent',
  data: {
    permissions: [],
    roles: [],
  },
  install() {
    Vue.prototype.$permissions = () => this
  },
  async fetchAccess(callback) {
    return axios.get(`/v1/companies/${Vue.prototype.$activeCompany().data.company.id}/user/permissions`)
      .then(user => {
        this.data.permissions = user.data.permissions
        this.data.roles = user.data.roles
        if (callback) {
          callback()
        }
      })
  },
  hasAny(haystack, needles) {
    let missing = false
    if (needles) {
      needles.forEach(needle => {
        if (!this.hasPermission(needle)) {
          missing = true
        }
      })
    }

    return missing === false
  },
  hasRole(role) {
    return this.data.roles && this.data.roles.includes(role)
  },
  hasAnyPermissions() {
    return !(!this.data.permissions.user.length && !this.data.permissions.company_user.length)
  },
  hasPermission(permission) {
    if (this.data.permissions) {
      if (this.data.permissions.company_user && this.data.permissions.company_user.includes(permission)) {
        return true
      }

      if (this.data.permissions.user && this.data.permissions.user.includes(permission)) {
        return true
      }
    }

    return false
  },
}
