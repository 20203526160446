import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import moment from 'moment'
import numberFormat from '@/libs/number-format'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.filter('formatDate', (value, defaultValue) => {
  const m = moment(value)
  return m.isValid() ? m.format('DD.MM.YYYY HH:mm') : defaultValue
})
Vue.filter('formatNumber', (value, decimalPlaces) => numberFormat.format(value, decimalPlaces))
Vue.filter('formatNumberWithZero', (value, decimalPlaces) => numberFormat.format(value, decimalPlaces) || 0)
