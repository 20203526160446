import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  // timeout: 1000,
  headers: { 'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
})

Vue.prototype.$http = axiosIns

export default axiosIns
