import Vue from 'vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'ActiveCompanyComponent',
  data: {
    company: null,
  },
  install() {
    Vue.prototype.$activeCompany = () => this
  },
  hasTag(tag) {
    return this.data.company.tags?.includes(tag) || false
  },
  fetchCompany() {
    if (Vue.prototype.$profile().data.user !== null && !this.data.company) {
      if (!useJwt.getActiveCompany() || useJwt.getActiveCompany() === 'null') {
        const companiesList = Vue.prototype.$profile().data.user.companies
        if (companiesList && companiesList.length > 0) {
          let company = companiesList[0]
          if (Vue.prototype.$profile().data.user.last_login_company_id) {
            companiesList.forEach(item => {
              if (item.id === Vue.prototype.$profile().data.user.last_login_company_id) {
                company = item
              }
            })
          }

          useJwt.setActiveCompany(company)
        }
      }
      this.data.company = useJwt.getActiveCompany()
    }
  },
}
