export default [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/company/list/CompanyList.vue'),
    meta: {
      pageTitle: 'Companies',
      permissions: ['view company'],
    },
  },
  {
    path: '/companies/add',
    name: 'add-company',
    component: () => import('@/views/company/add/CompanyAdd.vue'),
    meta: {
      pageTitle: 'Add company',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/companies',
        },
        {
          text: 'Add company',
          active: true,
        },
      ],
      permissions: ['create company'],
    },
  },
  {
    path: '/companies/:companyId',
    name: 'edit-company',
    props: true,
    component: () => import('@/views/company/edit/CompanyEdit.vue'),
    meta: {
      pageTitle: 'Edit company',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/companies',
        },
        {
          text: 'Edit company',
          active: true,
        },
      ],
      permissions: ['view company'],
    },
  },
]
