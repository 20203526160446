export default [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/parcel/Report.vue'),
    meta: {
      pageTitle: 'Reports',
      permissions: ['view report'],
    },
  },
]
