export default [
  {
    path: '/shipments/:shipmentId/declarations/:tab',
    name: 'shipmentDeclarations',
    component: () => import('@/views/shipments/Declarations.vue'),
    props: true,
    meta: {
      pageTitle: 'Shipment Customs declarations',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'Shipment',
          to: '/shipments/:shipmentId',
        },
        {
          text: 'Customs declarations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipments/:shipmentId/event-interface/:tab',
    name: 'shipmentEventInterface',
    component: () => import('@/views/shipments/EventInterface.vue'),
    props: true,
    meta: {
      pageTitle: 'Shipment event interface',
      breadcrumb: [
        {
          text: 'Shipments',
          to: '/shipments',
        },
        {
          text: 'Shipment',
          to: '/shipments/:shipmentId',
        },
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
]
