export default [
  {
    path: '/carriers',
    name: 'carriers',
    component: () => import('@/views/carriers/Index.vue'),
    meta: {
      pageTitle: 'Carriers',
      permissions: ['view carrier'],
    },
  },
]
