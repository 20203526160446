import Vue from 'vue'
import Pusher from 'vue-pusher'

Vue.use(Pusher, {
  api_key: process.env.VUE_APP_PUSHER_API_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
  },
})
