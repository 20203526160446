export default [
  {
    path: '/customs-log',
    name: 'customs-log',
    component: () => import('@/views/customs_log/Index.vue'),
    props: true,
    meta: {
      pageTitle: 'Customs message log',
      permissions: ['view customs-messages'],
    },
  },
]
