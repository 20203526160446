export default {
  // Endpoints
  loginEndpoint: '/v1/login',
  registerEndpoint: '/v1/register',
  logoutEndpoint: '/v1/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  userDataKey: 'userDataKey',
  storageRefreshTokenKeyName: 'refreshToken',
  activeCompanyKey: 'activeCompanyObject',
}
