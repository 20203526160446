import Vue from 'vue'
import axios from '@/libs/axios'

export default {
  name: 'ClassifiersComponent',
  data: {
    classifiers: [],
  },
  install() {
    Vue.prototype.$classifiers = () => this
  },
  async fetchClassifiers(callback) {
    return axios.get('/v1/classifiers')
      .then(classifiers => {
        this.data.classifiers = classifiers.data
        if (callback) {
          callback()
        }
      })
  },
  getClassifier(classifier) {
    if (!this.data?.classifiers) {
      return {}
    }
    const path = classifier.split('.')
    return path.reduce((acc, key) => (acc && acc[key] ? acc[key] : null), this.data?.classifiers) || {}
  },
  classifierContains(classifier, value) {
    if (this.data.classifiers) {
      if (this.data.classifiers[classifier]) {
        return this.data.classifiers[classifier].includes(value)
      }
    }

    return false
  },
  getOptions(classifier, addSelect = true) {
    const classifiers = this.getClassifier(classifier)

    // Recursive helper function to process nested structures
    const processEntries = entries => Object.entries(entries).map(
      ([id, val]) => {
        if (typeof val === 'object' && !Array.isArray(val)) {
          return { value: id, text: processEntries(val) }
        }
        return { value: id, text: val }
      },
    )

    let options = processEntries(classifiers)

    // Add "----select----" at the top level if requested
    if (addSelect) {
      options = [{ value: '', text: '----select----' }, ...options]
    }

    return options
  },
}
